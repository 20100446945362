import Link from 'next/link';
import logo from '@/assets/images/logo.png';
import Image from 'next/image';
import { useGetCategoriesHeaderQuery } from '@/generated/graphql';
import { GetCategoriesHeader } from '@/constants/defaultParams';
import RoutesConfig from '@/constants/url';
import { FbFooterIcon, InsFooterIcon, YtFooterIcon } from '@/assets/icons';
import useGetSeoConfig from '@/hooks/useGetSeoConfig';
import { useUser } from '@/contexts/AuthContext';
import { useAmp } from 'next/amp';

const FooterData = {
  contract: 'GP-TTĐT do Sở Thông tin & Truyền thông TP.HCM cấp ngày 30/10/2023',
  email: 'tinhaynhadat@gmail.com',
  phone: '0352739153',
  address: '23 Cửu Long, Phường 2, Tân Bình, TP.HCM',
  content: 'Nguyễn Viết Khim',
  description:
    '(Để tránh hiểu lầm là spam xin hãy nêu rõ chi tiết khi liên hệ chat lần đầu)',
};

const Footer = () => {
  const isAmp = useAmp();
  const { data, loading } = useGetCategoriesHeaderQuery({
    variables: GetCategoriesHeader,
  });
  const {
    address,
    phoneNumber,
    email,
    facebook,
    linkInstagram,
    linkYoutube,
    company,
    license,
    contentResponsibility,
  } = useGetSeoConfig();

  const { user } = useUser();

  return (
    <>
      {isAmp ? (
        <div
          className="amp-font-inter"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '44px 16px',
            background: '#D9DBDE',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}
          >
            <amp-img
              src="https://s3.ap-southeast-1.amazonaws.com/skydev-general.dev/logo-tinhay.png"
              width="171"
              height="72"
            ></amp-img>
            <div style={{ display: 'flex', gap: '28px' }}>
              <a
                href={facebook}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FbFooterIcon />
              </a>
              <a
                href={linkInstagram}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <InsFooterIcon />
              </a>
              <a
                href={linkYoutube}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <YtFooterIcon />
              </a>
            </div>
          </div>

          <div
            style={{
              fontSize: '13px',
              fontWeight: 'bold',
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}
          >
            <p className="uppercase">{company}</p>
            <p>
              Giấy phép:
              <strong style={{ fontWeight: '400' }}> {license}</strong>
            </p>
            <p>
              Email:
              <strong style={{ fontWeight: '400' }}> {email}</strong>
            </p>
            <p>
              Điện thoại:
              <strong style={{ fontWeight: '400' }}> {phoneNumber}</strong>
            </p>
            <p>
              Địa chỉ:
              <strong style={{ fontWeight: '400' }}> {address}</strong>
            </p>
            <p>
              Chịu trách nhiệm nội dung:
              <strong style={{ fontWeight: '400' }}>
                {' '}
                {contentResponsibility}
              </strong>
            </p>
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <div
              style={{ color: 'black', fontWeight: 'bold', fontSize: ' 13px' }}
            >
              <p style={{ textTransform: 'uppercase' }}>
                hợp tác truyền thông và quảng cáo
              </p>
              <p>
                Email:
                <strong style={{ fontWeight: '400' }}> {email}</strong>
              </p>
              <p>
                Điện thoại:
                <strong style={{ fontWeight: '400' }}> {phoneNumber}</strong>
              </p>
              <p>
                Địa chỉ:
                <strong style={{ fontWeight: '400' }}> {address}</strong>
              </p>
              <p>{FooterData.description}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link
                href={RoutesConfig.advertisement}
                style={{
                  textTransform: 'uppercase',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                quảng cáo
              </Link>
              <Link
                href={RoutesConfig.privacy}
                style={{
                  textTransform: 'uppercase',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                điều khoản
              </Link>
              <Link
                href={
                  user
                    ? `user/${RoutesConfig.user.index}`
                    : RoutesConfig.account.signUp
                }
                style={{
                  textTransform: 'uppercase',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                {user ? 'tài khoản' : 'đăng ký'}
              </Link>
              <Link
                href={RoutesConfig.contact}
                style={{
                  textTransform: 'uppercase',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                liên hệ
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            animation: 'animate-fadein 0.5s ease-in-out',
          }}
        >
          <div className="hidden container lg:flex items-center justify-between py-4">
            {data?.categories.map((item, index) => (
              <Link
                key={index}
                href={`/${item.slug}`}
                className="text-[15px] font-bold text-primary uppercase"
              >
                {item.name}
              </Link>
            ))}
            {loading &&
              Array.from({ length: 10 }).map((_, index) => (
                <div
                  className="w-full animate-pulse bg-white/50 h-5 rounded-md"
                  key={index}
                ></div>
              ))}
          </div>
          <div className="bg-[#D9DBDE]">
            <div className="container py-11 flex flex-col gap-5 lg:flex-row justify-between">
              <div className="flex-col flex gap-7">
                <Image
                  className="max-h-[72px]"
                  src={logo}
                  alt={'logo'}
                  height={72}
                  width={171}
                />
                <div className="flex gap-7">
                  <Link
                    href={facebook}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <FbFooterIcon />
                  </Link>
                  <Link
                    href={linkInstagram}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <InsFooterIcon />
                  </Link>
                  <Link
                    href={linkYoutube}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <YtFooterIcon />
                  </Link>
                </div>
              </div>
              <div className="text-[13px] font-bold text-black">
                <p className="uppercase">{company}</p>
                <p>
                  Giấy phép:
                  <strong className="font-normal"> {license}</strong>
                </p>
                <p>
                  Email:
                  <strong className="font-normal"> {email}</strong>
                </p>
                <p>
                  Điện thoại:
                  <strong className="font-normal"> {phoneNumber}</strong>
                </p>
                <p>
                  Địa chỉ:
                  <strong className="font-normal"> {address}</strong>
                </p>
                <p>
                  Chịu trách nhiệm nội dung:
                  <strong className="font-normal">
                    {' '}
                    {contentResponsibility}
                  </strong>
                </p>
              </div>
              <div className="flex flex-col lg:gap-10 gap-5">
                <div className="text-black font-bold text-[13px]">
                  <p className="uppercase">hợp tác truyền thông và quảng cáo</p>
                  <p>
                    Email:
                    <strong className="font-normal"> {email}</strong>
                  </p>
                  <p>
                    Điện thoại:
                    <strong className="font-normal"> {phoneNumber}</strong>
                  </p>
                  <p>
                    Địa chỉ:
                    <strong className="font-normal"> {address}</strong>
                  </p>
                  <p className="font-normal italic">{FooterData.description}</p>
                </div>
                <div className="flex justify-between">
                  <Link
                    href={RoutesConfig.advertisement}
                    className="uppercase text-[13px] text-black font-bold hover:text-primary"
                  >
                    quảng cáo
                  </Link>
                  <Link
                    href={RoutesConfig.privacy}
                    className="uppercase text-[13px] text-black font-bold hover:text-primary"
                  >
                    điều khoản
                  </Link>
                  <Link
                    href={
                      user
                        ? `user/${RoutesConfig.user.index}`
                        : RoutesConfig.account.signUp
                    }
                    className="uppercase text-[13px] text-black font-bold hover:text-primary"
                  >
                    {user ? 'tài khoản' : 'đăng ký'}
                  </Link>
                  <Link
                    href={RoutesConfig.contact}
                    className="uppercase text-[13px] text-black font-bold hover:text-primary"
                  >
                    liên hệ
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
